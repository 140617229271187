@import url("https://fonts.googleapis.com/css2?family=MuseoModerno:wght@500&family=Roboto:wght@100&family=Source+Code+Pro&display=swap");

* {
  margin: 0;
}

.Team1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-size: cover; /* Scale the image to cover the entire element */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-position: center; /* Center the image within the element */
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
  flex-direction: column;
}

.BackgroundTeam {
  background-color: #010d2b;
  background-position: center;
  position: relative;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.TeamFlex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.Alexander {
  margin: 50px;
  border-radius: 0vh;
  width: 40vh;
  box-shadow: 25px 25px 10px;
  transition: transform 0.5s;
}
.Alexander:hover {
  transform: scale(1.05);
}

.AlexanderDescription {
  width: 40vh;
  margin: 50px;
  color: white;
  font-family: "Source Code Pro", monospace;
  border-color: white;
  border-width: 1px;
}

.AlexanderName {
  font-size: 4vh;
  font-family: "MuseoModerno", cursive;
}

.AlexanderTitle {
  font-size: 2vh;
  font-family: "MuseoModerno", cursive;
}

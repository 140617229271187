* {
  margin: 0;
}

.App {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-size: cover; /* Scale the image to cover the entire element */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-position: center; /* Center the image within the element */
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
  flex-direction: column;
}

a {
  text-decoration: none;
}
h1 {
  color: white;
  margin: 5px;
}

.Background {
  background-image: url("Expolsion.jpeg");
  background-position: center;
  position: relative;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.Description {
  background-color: black;
  background-position: center;
  position: relative;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.StratoDiv {
  border-radius: 5vh;
  margin: 5vh;
  margin-bottom: 2vh;
}

.StratoLab {
  color: white;
  margin: 2vh;
  font-size: 400%;
  width: 28vh;
  text-shadow: 8px 8px black;
  transition: transform 0.5s;
}

.StratoLab:hover {
  transform: scale(1.1);
}

.lowerHome {
  background-image: url("https://www.nasa.gov/sites/default/files/styles/full_width_feature/public/thumbnails/image/apollo08_earthrise.jpg");
  background-position: center;
  background-size: cover;
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lowerCreditImage {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 1vh;
  margin-bottom: 1vh;
  color: white;
}

.lowerQuote {
  color: white;
  padding-left: min(10vh, 5vh);
}

.lowerQuoteCredit {
  color: white;
  margin-top: 2vh;
  padding-left: min(10vh, 5vh);
}

@import url("https://fonts.googleapis.com/css2?family=MuseoModerno:wght@100&family=Roboto:wght@100&family=Source+Code+Pro&display=swap");

* {
  margin: 0;
}

a {
  text-decoration: none;
}

nav {
  background-color: rgba(0, 0, 0, 0.1);
  min-height: 50px;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
  display: flex;
}

.ReturnHome {
  background-image: url("./MissionCubeLogoWhite.svg");
  min-height: 70px;
  min-width: 70px;
  background-position: center;
  position: relative;
  background-size: cover;
  margin-left: 10px;
  margin: 10px;
}

.NavAbout {
  font-family: "MuseoModerno", cursive;
  margin: 10px;
  margin-top: 15px;
  font-size: 25px;
  display: block;
}
.NavAbout:hover {
  background-color: rgba(155, 155, 155, 0.8);
}

@media screen and (max-width: 286px) {
  .NavAbout {
    display: none;
  }
}

.NavStore {
  font-family: "MuseoModerno", cursive;
  margin: 10px;
  margin-top: 15px;
  font-size: 25px;
  display: block;
}
.NavStore:hover {
  background-color: rgba(155, 155, 155, 0.8);
}

@media screen and (max-width: 450px) {
  .NavStore {
    display: none;
  }
}

.NavStratoLab {
  font-family: "MuseoModerno", cursive;
  margin: 10px;
  margin-top: 15px;
  font-size: 25px;
  display: block;
}
.NavStratoLab:hover {
  background-color: rgba(155, 155, 155, 0.8);
}

@media screen and (max-width: 714px) {
  .NavStratoLab {
    display: none;
  }
}

.NavOpen {
  font-family: "MuseoModerno", cursive;
  margin: 10px;
  margin-top: 15px;
  font-size: 25px;
  display: block;
}
.NavOpen:hover {
  background-color: rgba(155, 155, 155, 0.8);
}

@media screen and (max-width: 1037px) {
  .NavOpen {
    display: none;
  }
}

.NavTeam {
  font-family: "MuseoModerno", cursive;
  margin: 10px;
  margin-top: 15px;
  font-size: 25px;
  display: block;
}
.NavTeam:hover {
  background-color: rgba(155, 155, 155, 0.8);
}

@media screen and (max-width: 1157px) {
  .NavTeam {
    display: none;
  }
}

.NavApply {
  font-family: "MuseoModerno", cursive;
  margin: 10px;
  margin-top: 15px;
  font-size: 25px;
  display: block;
}
.NavApply:hover {
  background-color: rgba(155, 155, 155, 0.8);
}

@media screen and (max-width: 1300px) {
  .NavApply {
    display: none;
  }
}

.NavContact {
  font-family: "MuseoModerno", cursive;
  margin: 10px;
  margin-top: 15px;
  font-size: 25px;
  display: block;
}
.NavContact:hover {
  background-color: rgba(155, 155, 155, 0.8);
}
@media screen and (max-width: 1404px) {
  .NavContact {
    display: none;
  }
}

.NavResources {
  font-family: "MuseoModerno", cursive;
  margin: 10px;
  margin-top: 15px;
  font-size: 25px;
  display: block;
}

.NavResources:hover {
  background-color: rgba(155, 155, 155, 0.8);
}

@media screen and (max-width: 1524px) {
  .NavResources {
    display: none;
  }
}

.DropDownButton {
  background-color: transparent;
  border-color: transparent;
  margin-left: auto;
  margin-right: 20px;
  display: block;
}

.MenuSymbol {
  color: white;
}

@media screen and (min-width: 1524px) {
  .DropDownButton {
    display: none;
  }
}

.MenuBarDiv {
  background-color: rgba(0, 0, 0, 0.7);
  border: 0.5px solid rgba(255, 255, 255, 0.2);
}

.NavAboutBar {
  font-family: "MuseoModerno", cursive;
  margin: 10px;
  margin-top: 15px;
  font-size: 20px;
  display: none;
}
.NavAboutBar:hover {
  background-color: rgba(155, 155, 155, 0.8);
}

@media screen and (max-width: 286px) {
  .NavAboutBar {
    display: block;
  }
}

.NavStoreBar {
  font-family: "MuseoModerno", cursive;
  margin: 10px;
  margin-top: 15px;
  font-size: 20px;
  display: none;
}
.NavStoreBar:hover {
  background-color: rgba(155, 155, 155, 0.8);
}

@media screen and (max-width: 450px) {
  .NavStoreBar {
    display: block;
  }
}

.NavStratoLabBar {
  font-family: "MuseoModerno", cursive;
  margin: 10px;
  margin-top: 15px;
  font-size: 20px;
  display: none;
}
.NavStratoLabBar:hover {
  background-color: rgba(155, 155, 155, 0.8);
}

@media screen and (max-width: 714px) {
  .NavStratoLabBar {
    display: block;
  }
}

.NavOpenBar {
  font-family: "MuseoModerno", cursive;
  margin: 10px;
  margin-top: 15px;
  font-size: 20px;
  display: none;
}
.NavOpenBar:hover {
  background-color: rgba(155, 155, 155, 0.8);
}

@media screen and (max-width: 1037px) {
  .NavOpenBar {
    display: block;
  }
}

.NavTeamBar {
  font-family: "MuseoModerno", cursive;
  margin: 10px;
  margin-top: 15px;
  font-size: 20px;
  display: none;
}
.NavTeamBar:hover {
  background-color: rgba(155, 155, 155, 0.8);
}

@media screen and (max-width: 1157px) {
  .NavTeamBar {
    display: block;
  }
}

.NavApplyBar {
  font-family: "MuseoModerno", cursive;
  margin: 10px;
  margin-top: 15px;
  font-size: 20px;
  display: none;
}
.NavApplyBar:hover {
  background-color: rgba(155, 155, 155, 0.8);
}

@media screen and (max-width: 1300px) {
  .NavApplyBar {
    display: block;
  }
}

.NavContactBar {
  font-family: "MuseoModerno", cursive;
  margin: 10px;
  margin-top: 15px;
  font-size: 20px;
  display: none;
}
.NavContactBar:hover {
  background-color: rgba(155, 155, 155, 0.8);
}
@media screen and (max-width: 1404px) {
  .NavContactBar {
    display: block;
  }
}

.NavResourcesBar {
  font-family: "MuseoModerno", cursive;
  margin: 10px;
  margin-top: 15px;
  font-size: 20px;
  display: none;
}
.NavResourcesBar:hover {
  background-color: rgba(155, 155, 155, 0.8);
}
@media screen and (max-width: 1524px) {
  .NavResourcesBar {
    display: block;
  }
}

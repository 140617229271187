* {
  margin: 0;
}

.Open1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-size: cover; /* Scale the image to cover the entire element */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-position: center; /* Center the image within the element */
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
  flex-direction: column;
}

.BackgroundOpen {
  background-color: black;
  background-position: center;
  position: relative;
  background-size: cover;
  width: 100%;
  height: 100vh;
}
